* {
  box-sizing: border-box;
}
:root {
  --orange: #ec7955;
  --lightblue: #e0fbfc;
  --darkblue: #3d5a80;
  --midblue: #98c1d9;
  --dark: #293241;
  --common-font: "Poppins";
  --square-font: "Quantico";
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, sans-serif;
  display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
